.popup-surrounding {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
}

.popup-wrapper {
    top: 50%;
    left: 50%;
    padding: 2rem;
    overflow: auto;
    position: fixed;
    max-width: 70vw;
    max-height: 70vh;
    min-width: 900px;
    min-height: 700px;
    border-radius: 1rem;
    box-sizing: border-box;
    background-color: white;
    transform: translate(-50%, -50%);
    box-shadow: 5px 10px 15px #545454;
    border: 1px solid rgb(223, 223, 223);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.popup-wrapper::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .popup-wrapper {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.popup-header {
    /* background-color: blue; */
    width: 100%;
    display: flex;
    box-sizing: border-box;
}

.popup-title {
    color: black;
    font-weight: 600;
    font-size: 1.8rem;
}

.popup-close-button {
    /* background-color: green; */
    align-self: center;
    margin-left: auto;
    font-size: 1rem;
    font-weight: 600;
    color: grey;
    text-decoration: underline;
    /* height: 20px;
    width: 20px; */
}

.popup-content-wrapper {
    /* background-color:yellow; */
    margin-top: 2rem;
    overflow: hidden;
    font-size: 1.3rem;
}

.popup-content-wrapper p {
    text-align: left;
}

@media only screen and (max-width: 768px) {
    .popup-wrapper {
        width: 90vw;
        height: 90vh;
        min-width: unset;
        min-height: unset;
        max-width: unset;
        max-height: unset;
    }
}