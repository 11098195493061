.editTaskWrapper {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
}

.menuWrapper {
    position: fixed;
    background-color: var(--popup-background);
    /* border-radius: 0.5rem; */
    padding:  0.4rem;
    /* border: 3px solid var(--tertiary-background); */


    display: flex;
    flex-direction: column;
}

.button {
    width: 10rem;
    margin: 0.23rem 0;
}