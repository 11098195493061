.taskTrackingBackground {
    background-color: var(--primary-background);
    margin-top: 5rem;
    width: 75%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .taskTrackingBackground {
        width: 100%;
    }  
}

