.settingsWrapper {
    background-color: var(--primary-background);
    margin-top: 5rem;
    width: 75%;
    display: flex;
    padding-left: 10vw;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


.themeSelection {
    color: var(--primary-text);
    width: 100%;
    font-size: 2rem;
}

.settingsWrapper h3 {
    margin-bottom: 1vw;
}

.settingsWrapper select, input {
    margin-right: auto;
    height: 2rem;
    width: 8rem;
    border-radius: 0.3rem;
    border-color: var(--primary-background-hover);
    background-color: var(--tertiary-background);
    color: var(--primary-text);
}

.settingsWrapper button {
    margin-right: auto;
    margin-top: 2rem;
}

@media only screen and (max-width: 768px) {
    .taskTrackingBackground {
        width: 100%;
    }  
}

