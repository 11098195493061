.button {
    /* width: 100%; */
    font-size: 1.2rem;
    border-radius: 0.3rem;
    padding: 0.6rem 1rem;
    background: none;
    outline: none;
    border: none;
    color: var(--primary-text);
    background-color: var(--button-normal);
}

.button:hover {
    background-color: var(--button-normal-hover);
}

.button.delete {
    background-color: var(--button-delete);
}
.button.delete:hover {
    background-color: var(--button-delete-hover);
}