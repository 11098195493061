.headerWrapper {
    display: none;
    overflow: hidden;
}

.menuWrapper {
    display: none;
}

@media only screen and (max-width: 768px) {
    .headerWrapper {
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--secondary-background);
        border-bottom: 3px solid var(--tertiary-background);
        position: fixed;
        width: 100%;
    } 

    .headerWrapper .hamburgerMenu {
        position: absolute;
        left: 0.5em;
    }

    .brandingFull {
        display: flex;
    }

    .headerWrapper svg {
        height: 60%;
    }

    .productName {
        font-size: 0.7em;
        font-weight: bold;
        line-height: 0.8em;
        color: white;
        font-family: 'Righteous', cursive;
    }

    .companyName {
        font-size: 0.4em;
        font-weight: lighter;
        text-align: right;
        color: rgb(199, 199, 199);
    }

    .menuWrapper {
        display: block;
        overflow: hidden;
        background-color: var(--secondary-background);
        border-bottom: 3px solid var(--tertiary-background);
        position: fixed;
        top: 3.5rem;
        width: 100%;
        padding: 0;
        transition: all 0.5s ease-in-out;
        height: 0px;
    }

    .menuWrapperEnabled {
        height: 100%;
        padding: 1em 0;
    }
}


