.appWrapper {
    width: 100vw;
    height: 100%;
    background-color: var(--primary-background);
    display: flex;
}

.baseComponentSidebar {
    width: 30%;
    max-width: 250px;
    overflow: hidden;
}

.baseComponentContent {
    width: 100%;
    min-height: 100vh;
}

@media only screen and (max-width: 768px) {
    .baseComponentSidebar {
        width: 0;
    }  
}

.baseComponentSidebarMobile {
    width: 25rem;
}