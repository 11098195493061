.headerWrapper {
    height: 100vh;
    background-color: var(--secondary-background);
    border-right: 3px solid var(--tertiary-background);
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    width: 23vw;
    max-width: 250px;
}

.sidebarPlaceholder {
    height: 100%;
    display: inline;
}

.branding {
    margin-bottom: 1vw;
    margin-top: 1vw;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
}

.branding svg {
    width: 60%;
}

.brandingName {
    margin-left: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.productName {
    color: white;
    font-size: 1.6vw;
    font-weight: 900;
    font-family: 'Righteous', cursive;
}


.companyName {
    color: rgb(199, 199, 199);
    font-size: 0.4em;
    /* line-height: 0.8rem; */
    font-weight: 300;
    text-align: end;
}

.headerFooter {
    margin-top: auto;
    text-align: center;
    color: rgb(148, 148, 148);
    font-weight: 900;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
}

.headerFooter span {
    font-weight: 300;
    margin-top: auto;
    margin-left: 0.4rem;
    font-size: 1rem;
}

.headerFooter svg {
    width: 6rem;
}



@media only screen and (max-width: 768px) {
    .productName {
        font-size: 1.9vw;
    }

    .sidebarPlaceholder {
        display: none;
    }

}

