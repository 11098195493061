.quickAddWrapper {
    top: 89vh;
    width: 3vw;
    height: 3vw;
    right: 27vw;
    display: flex;
    position: fixed;
    min-width: 50px;
    min-height: 50px;
    border-radius: 20vw;
    align-items: center;
    justify-content: center;
    background-color: #00DCFA;
}

.quickAddWrapper:hover {
    transform: scale(1.1);
    cursor: pointer;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

@media only screen and (max-width: 768px) {
    .quickAddWrapper {
        top: 80vh;
        right: 5vw;
    }  
}