.portfolioWrapper {
    width: 100vw;
}

.brandingSection {
    height: 90vh;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}

.brandingLeftSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
}

.brandingRightSection {
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
}

.brandingRightSection img {
    max-width: 38vw;
}

.brandLogo {
    width: 18rem;
    max-width: 80vw;
}

.tagline {
    font-size: 1.8rem;
    line-height: 1.9rem;
    margin: 0;
    font-weight: 400;   
    color: grey;
    text-align: center;
    max-width: 40vw;
}

.goToAppButton {
    background-color: #fd8026;
    padding: 1% 4%;
    box-sizing: border-box;
    color: white;
    font-weight: 600;
    margin-top: 1.9rem;
    margin-bottom: 2.5rem;
    border-radius: 0.5rem;
    box-shadow: 5px 8px 10px #a5a5a5;
}

.goToAppButton:hover {
    transform: scale(1.02, 1.02);
}

.goToAppButton:hover {
    background-color: #fa7313;
    text-decoration: underline;
}

.featuresSection {
    width: 100vw;
    /* height: 50vh; */
    /* background: #fd8026; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}

.featureCard {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 1rem 10rem 1rem;
    width: 20rem;
    height: 15rem;
    border-radius: 1rem;
    box-sizing: border-box;
    padding: 1%;
    box-shadow: 4px 6px 10px #d6d6d6;
    border: 1px solid rgb(228, 228, 228);
    position: relative;
}

.featureCard:hover {
    transform: scale(1.02, 1.02);
}

.featureCard h3,p {
    text-align: center;
}

.featureCard h3 {
    margin: 8% 0 0 0;
}

.featureCard p {
    margin: 5% 0;
    font-size: 0.7em;
    line-height: 1em;
    color: grey;
}

.featureCard img {
    max-width: 40%;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
}


.footer {
    width: 100vw;
    padding: 0 2rem;
    background-color: rgb(30, 30, 30);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-height: 10vh;
}

.footer a {
    margin-right: 1rem;
    font-size: 1rem;
    color: white;
    box-sizing: border-box;
}

.footer a:hover {
    color: #fa7313;
}

.footer p {
    font-size: 1rem;
    color: white;
    margin-left: auto;
    box-sizing: border-box;

}


@media only screen and (max-width: 768px) {

    .brandLogo {
        width: 60vw;
    }

    .tagline {
        max-width: 80vw;
        font-size: 1.3rem;
        margin-top: 1rem;
    }

    .brandingSection {
        max-height: 90vh;
        flex-direction: column;
    }

    .goToAppButton {
        margin-top: 4rem;
        padding: 2% 5%;
        font-size: 1.2rem;
    }



    .brandingLeftSection, .brandingRightSection {
        width: 100%;
        /* height: 50%; */
    }

    .brandingLeftSection {
        padding: 10vw 0;
    }

    .brandingLeftSection {
        justify-content: flex-end;
    }
    .brandingRightSection img {
        width: 93%;
        max-width: 60vw;
    }

    .featuresSection {
        margin-top: 10vw;
        flex-direction: column;
        padding: 2rem 0;
    }

    .featureCard {
        margin: 4rem 1rem;
        width: 80vw;
        height: 70vw;
    }

    .footer {
        display: flex;
        flex-direction: column;
        padding: 2rem;
    }

    .pages {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .pages a {
        margin: 1% 0;
    }

    .credit {
        /* background-color: green; */
        margin: 0 auto;
        margin-top: 3rem;

    }
}