.wrapperStyles {
    width: 0;
    overflow: hidden;
    height: 3rem;
    display: flex;
    margin: 0.3rem 0 0.3rem 0;
    transition: width 0.05s ease-in-out;
    opacity: 0;
}

.wrapperFullStyle {
    width: 88%;
    opacity: 1;
}

.wrapperStyle_newTask {
    margin-bottom: 3vw;

}

.statusIndicatorStyle {
    width: 0.3rem;
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
}


.statusIndicatorStyle_newTask {
   display: none;
}

.statusIndicatorStyle_runningTask {
   background-color: var(--task-running-denotation);
}

.statusIndicatorStyle_pausedTask {
   background-color: var(--task-paused-denotation);
}

.taskViewStyles {
    width: 100%;
    height: calc(100% - 6px);
    background-color: var(--secondary-background);
    border-radius: 0 0.5rem 0.5rem 0;
    border: 3px solid var(--task-border);
    display: flex;
    align-items: center;
}

.taskViewStyles_completedTask {
    border-radius: 0.5rem;
    background-color: var( --task-completed-background);
    color: var(--task-completed-text);

}
.taskViewStyles_pausedTask {
    color: var(--task-paused-text);
    background-color: var( --task-paused-background)
}
.taskViewStyles_runningTask {
    color: var(--task-running-text);
    background-color: var(--task-running-background)
}

.taskViewStyles:hover {
    background-color: var(--task-hover);
}
.taskViewStyles_newTask {
    border-radius: 0.5rem;
    justify-content: center;
    border-style: dashed;

}
