.menuItemWrapper {
    display: flex;
    align-items: center;
    border-top: 3px solid var(--tertiary-background);
    height: 1.5em;
    padding: 0.1em 1em;
}

.hearedMenuStyle {
    justify-content: center;
    height: 1.5em;
}

.menuItemWrapper:hover {
    background-color: var(--tertiary-background-hover);
}

.menuItemWrapper.focused {
    background-color: var(--tertiary-background);
}

.menuItemWrapper h3 {
    color: var(--secondary-text);
    margin-left: 0.2em;
    font-size: 0.9rem;
}

.menuItemWrapper h3.selected {
    color: var(--primary-text);
}

.hearedMenuStyle.menuItemWrapper h3 {
    font-size: 1.0rem;
}

.menuItemWrapper svg {
    width: 1rem;
    margin-right: 0.2em;
    transition: transform 0.2s ease-in-out;
}

.menuItemWrapper:hover svg {
    transform: scale(1.3);
}

.headerMenuStyle.menuItemWrapper svg {
    width: 1.3rem;
}