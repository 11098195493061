.activeTaskWrapper {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 100%;
    width: 100%;
}

.taskName {
    font-size: 0.9rem;
    margin-left: 0.5em;
}

.time {
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: 0.5em;
}