.addNewTaskWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    scroll-margin:  20vh;
}

.addNewTaskImage {
    margin-right: 0.5em;
    width: 1.7rem;
}

.addNewTaskTitle {
    font-size: 0.9rem;
    margin-left: 0.5em;
    color: var(--primary-text);
    font-weight: normal;
}

.inputStyle {
    width: 10%;
    height: 80%;
    border: none;
    outline: none;
    background: none;
    padding-left: 1em;
    font-size: 1rem;
    color: var(--primary-text);
    transition: width 0.1s ease-in-out;}

.inputStyleFull {
    width: 100%
}

.titleWrapper {
    display: flex;
    position: relative;
}

.tootltip {
    opacity: 0;
    background-color: var(--secondary-background-hover);
    color: var(--primary-text);
    text-align: center;
    padding: 0 0.2rem;
    border-radius: 0.2rem;
    position: absolute;
    font-size: 1rem;
    transition: all 0.4s ease-in-out;
    transform: translate(120%, -50%);
    top: 50%;

}

.titleWrapper:hover .tootltip {
    opacity: 1;
}